.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

/*# sourceMappingURL=index.081b9318.css.map */
